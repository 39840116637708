import {useRouter} from 'next/router';
import Script from 'next/script';
import isSmartPhone from '../../config/isSmartPhone';
import type {GetUserDataForAnalyticsResponse} from '../../core/repositories/usersMeRepository';

type Props = {
  userDataForAnalytics?: GetUserDataForAnalyticsResponse;
};

type DataByClient = {
  is_user_logged_in: 'Yes' | 'No';
  is_smartphone_view: 'Yes' | 'No';
  column_or_recruiting: undefined;
  path: string;
  referrer: string;
};

type DataForAnalytics =
  | (GetUserDataForAnalyticsResponse & DataByClient)
  | DataByClient;

export const ScriptForGA: React.FC<Props> = ({userDataForAnalytics}) => {
  const router = useRouter();
  let dataForAnalytics: DataForAnalytics = {
    is_user_logged_in: userDataForAnalytics ? 'Yes' : 'No',
    is_smartphone_view: isSmartPhone() ? 'Yes' : 'No',
    column_or_recruiting: undefined,
    path: router.asPath.replace(/\?.*$/, '').replace(/#.*$/, ''),
    referrer: typeof window === 'undefined' ? '' : window?.document?.referrer,
  };
  if (userDataForAnalytics) {
    dataForAnalytics = {
      ...userDataForAnalytics,
      ...dataForAnalytics,
    };
  }

  const stringifiedData: string = JSON.stringify(
    stringifyValues(dataForAnalytics),
  );

  return (
    <>
      {/* GA用 */}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-C3EXT120D0"
      />
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-C3EXT120D0');
            `,
        }}
      />
      <Script
        id="ga"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              dataLayer = [${stringifiedData}];
            `,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WLB85M');
            `,
        }}
      />
      {/* End Google Tag Manager */}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagservices.com/tag/js/gpt.js"
      />
      <Script
        id="googletag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              var googletag = googletag || {}; googletag.cmd = googletag.cmd || [];
            `,
        }}
      />
    </>
  );
};

// オブジェクトのバリューが文字列でない場合、文字列に変換する
function stringifyValues(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const newObj: any = {};

  for (const key of Object.keys(obj)) {
    newObj[key] = typeof obj[key] === 'string' ? obj[key] : String(obj[key]);
  }

  return newObj;
}
