import {
  useUserMeInterestUnreadCount,
  useUserMeScoutUnreadCount,
} from '../repositories/usersMeRepository';
import {type UserType, useAuth} from './useAuth';

// TODO: ./auth配下のものに移行する

type AuthAndCounts = {
  accessToken: string;
  user: UserType | undefined;
  isIdle: boolean;
  scoutsCount: number;
  interestsCount: number;
  signOut: () => void;
  isSignedIn: boolean;
};

export const useAuthAndCounts = (): AuthAndCounts => {
  // MEMO: アクセストークンなどの認証情報を取得
  const {user, accessToken, signOut, isIdle, isSignedIn} = useAuth();

  const {count: scoutsCount} = useUserMeScoutUnreadCount({
    config: {enabled: isSignedIn},
  });
  const {count: interestsCount} = useUserMeInterestUnreadCount({
    config: {enabled: isSignedIn},
  });

  return {
    accessToken,
    user,
    isIdle,
    scoutsCount: scoutsCount ?? 0,
    interestsCount: interestsCount ?? 0,
    signOut,
    isSignedIn: !isIdle && Boolean(user),
  };
};
