import {useEffect, useState} from 'react';
import {
  GOOGLE_AD_MANAGER_URL,
  GoogleAdBannerMap,
  type GoogleAdBannerName,
} from '../../config/constants/googleAdBanner';
import {
  type AdvertisementUserSchema,
  useGetAdvertisementUser,
} from '../../core/repositories/advertisementRepository';

type GoogleAdBannerNames = GoogleAdBannerName[];

export const useGoogleAdBanner = (googleAdBannerNames: GoogleAdBannerNames) => {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const {data: advertisementUser} = useGetAdvertisementUser({});

  useEffect(() => {
    return () => {
      googletag.cmd.push(() => {
        googletag.destroySlots();
      });
    };
  }, []);

  useEffect(() => {
    if (advertisementUser?.ok) {
      callHeadScript(googleAdBannerNames, advertisementUser.value);
    }
  }, [advertisementUser, shouldRefetch]);

  return {setShouldRefetch};
};

const callHeadScript = (
  googleAdBannerNames: GoogleAdBannerNames,
  user: AdvertisementUserSchema,
) => {
  googletag.cmd.push(() => {
    for (const googleAdBannerName of googleAdBannerNames) {
      const {id} = GoogleAdBannerMap[googleAdBannerName];
      const {size} = GoogleAdBannerMap[googleAdBannerName];
      if (
        !googletag
          .pubads()
          .getSlots()
          .some((slot) => slot.getSlotElementId() === id)
      ) {
        const URL = GOOGLE_AD_MANAGER_URL + googleAdBannerName;
        const slot = googletag.defineSlot(URL, size, id);

        if (slot) {
          slot.addService(googletag.pubads());
        }
      }
    }

    googletag.pubads().setTargeting('university', user.universityID.toString());
    googletag.pubads().setTargeting('g_year', user.graduationYear.toString());

    googletag.enableServices();

    for (const googleAdBannerName of googleAdBannerNames) {
      googletag.display(GoogleAdBannerMap[googleAdBannerName].id);
    }
  });
};
