import classNames from 'classnames';
import Link from 'next/link';
import {useRouter} from 'next/router';
import type {FC} from 'react';
import {MdOutlineAddCircleOutline} from 'react-icons/md';
import {useNotificationsCounts} from '../../../core/repositories/notificationsRepository';
import {useAuthAndCounts} from '../../../core/usecases/useAuthAndCounts';
import {MenuButtonItem} from './MenuButtonItem';
import {MenuGroup} from './MenuGroup';
import {MenuItem} from './MenuItem';

// Import { useUnreadScoutMessagesCount } from "@/features/scouts";

type Props = {
  className?: string;
};

export const Sidebar: FC<Props> = ({className}) => {
  const {
    signOut,
    scoutsCount: scoutUnreadCount,
    interestsCount: interestUnreadCount,
  } = useAuthAndCounts();
  const {counts} = useNotificationsCounts({});

  // MEMO: API側がmasterにマージされたら組み込む
  // const { count: scoutMessagesUnreadCount } = useUnreadScoutMessagesCount({ accessToken });
  const router = useRouter();

  return (
    <div className={classNames('text-base', className)}>
      <div className="bg-primary-1 px-12 py-8 font-bold text-white-1">
        マイページ
      </div>
      <div className="shadow-nav">
        <MenuGroup title="新着情報">
          <MenuItem
            href="/"
            label="すべての新着"
            selected={router.asPath === '/'}
          />
          <MenuItem
            href="/following/company"
            label="フォロー企業の新着"
            notification={counts.favoritedCompany}
            selected={router.asPath === '/following/company'}
          />
          <MenuItem
            href="/mypage/favs/companies"
            label="フォロー企業の管理・追加"
            icon={<MdOutlineAddCircleOutline />}
          />
        </MenuGroup>
        <MenuGroup title="スカウト・興味あり">
          <MenuItem
            href="/scouts"
            label="スカウトの確認"
            notification={scoutUnreadCount}
          />
          <MenuItem
            href="/myInterests"
            label="あなたに興味を持っている企業"
            notification={interestUnreadCount}
          />
          <MenuItem href="/mypage/profile" label="プロフィールの編集" />
          <MenuItem
            href="/mypage/profile/edit/scout_setting"
            label="スカウト受信設定"
          />
        </MenuGroup>
        <MenuGroup title="スケジュール/選考管理">
          <MenuItem href="/schedule" label="スケジュール" />
          <MenuItem href="/event" label="エントリー中の選考/書類提出" />
        </MenuGroup>
        <MenuGroup title="クリップしたページ">
          <MenuItem href="/clip/reports" label="ES・体験記" />
          <MenuItem href="/clip/posts" label="就活コラム" />
        </MenuGroup>
        <MenuGroup title="その他メニュー">
          <MenuItem href="/mypage/profile/edit/dm" label="メール受信設定" />
          <MenuItem label="ログアウト" onClick={signOut} />
        </MenuGroup>
      </div>
      <div className="mt-8 flex flex-col gap-8">
        <MenuButtonItem
          href="/mypage/entrysheets"
          label="エントリーシートの投稿/管理"
          className="bg-accent-1 border-none text-white"
        />
        <MenuButtonItem
          href="/mypage/reports"
          label="選考体験記の投稿/管理"
          className="bg-accent-1 border-none text-white"
        />
        <MenuButtonItem
          href="/lp/to_liiga_account"
          label="内定者の方はこちら"
        />
      </div>
      <div className="mt-8 shadow-nav">
        <div className="bg-primary-1 px-12 py-8 font-bold text-white-1">
          運営からのお知らせ
        </div>
        <div className="bg-white p-12 text-black-3">
          <div>
            自己PRを充実させると、スカウトが届く可能性UP！あなたの魅力を伝えよう。
          </div>
          <Link
            href="/mypage/profile/edit/pr_message"
            className="text-primary-1 hover:font-bold"
          >
            自己PRを入力する
          </Link>
        </div>
      </div>
    </div>
  );
};
