import {Box, Text} from '@mantine/core';
import {parseCookies} from 'nookies';
import type {FC} from 'react';
import {MdWarning} from 'react-icons/md';
import {useUsersMe} from '../../core/repositories/usersMeRepository';
import {validateCookie} from '../../core/usecases/auth/checkOauthTokenExistence';
import {CommonLink} from './CommonLink';

export const EmailConfirmNoticeForSP: FC = () => {
  const cookies = parseCookies();
  const {isValid} = validateCookie(cookies);

  const {data: usersMe} = useUsersMe({
    config: {enabled: isValid},
  });

  return usersMe?.ok && !usersMe.value.isEmailConfirmed ? (
    <Box className="flex h-[120px] flex-col items-center justify-center bg-notification  p-[15px] text-[20px] font-bold text-white">
      <Box className="flex">
        <MdWarning size={32} />
        <Text className="mr-2">
          いつも使うメールアドレスが認証されていません。
        </Text>
      </Box>
      <CommonLink href="/mypage/profile" className="items-center underline">
        認証について
      </CommonLink>
    </Box>
  ) : null;
};
