import {type AppError, InvalidTokenError, type Result} from '@packages/utils';
import {type UseQueryOptions, useQuery} from '@tanstack/react-query';
import {parseCookies} from 'nookies';
import type {z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {
  AdvertisementMypageJackBannerSettingSchema,
  AdvertisementUserSchema,
  getBannerSchema,
  getBannerZSchema,
  getJackBannerSchema,
} from '../entities/advertisement/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';
import {useIsSessionExpired} from '../usecases/auth/useIsSessionExpired';

export type AdvertisementUserReturnValueType = Result<
  AdvertisementUserSchema,
  AppError
>;
export type AdvertisementUserSchema = z.infer<typeof AdvertisementUserSchema>;

type GetAdvertisementUserArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
};

export const getAdvertisementUser = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: GetAdvertisementUserArgs): Promise<AdvertisementUserReturnValueType> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    return defaultClient.get(
      `/${version}/advertisements/user`,
      AdvertisementUserSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: false,
    request,
    version,
  });

  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetAdvertisementUserOptions = {
  config?: UseQueryOptions<AdvertisementUserReturnValueType>;
  version?: string;
};

export const useGetAdvertisementUser = ({
  config,
  version = DEFAULT_API_VERSION,
}: UseGetAdvertisementUserOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<AdvertisementUserReturnValueType>({
    ...config,
    queryKey: ['advertisementUser'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getAdvertisementUser({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type GetBannerSchema = z.infer<typeof getBannerSchema>;

type ResultGetBannerSchema = Result<GetBannerSchema, AppError>;

type GetBannerArgs = {
  version?: string;
  cookies: Partial<Record<string, string>>;
};

export const getBanner = async ({
  version = DEFAULT_API_VERSION,
  cookies,
}: GetBannerArgs): Promise<ResultGetBannerSchema> => {
  const request = async (token?: string) => {
    if (token) {
      defaultClient.setToken(token);
    }

    return defaultClient.get(
      `/${version}/advertisements/banners`,
      getBannerSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: false,
    request,
    version,
  });

  return result;
};

type UseGetBannerOptions = {
  config?: UseQueryOptions<ResultGetBannerSchema>;
  version?: string;
};

export const useGetBanner = ({config, version}: UseGetBannerOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetBannerSchema>({
    ...config,
    queryKey: ['banners'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getBanner({cookies, version});

      if (!result?.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type GetBannerZSchema = z.infer<typeof getBannerZSchema>;
type ResultGetBannerZSchema = Result<GetBannerZSchema, AppError>;

type GetBannerZArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getBannerZ = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetBannerZArgs): Promise<ResultGetBannerZSchema> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/advertisements/banner-z`,
      getBannerZSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetBannerZOptions = {
  version?: string;
  config?: UseQueryOptions<ResultGetBannerZSchema>;
};

export const useGetBannerZ = ({version, config}: UseGetBannerZOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetBannerZSchema>({
    ...config,
    queryKey: ['bannerZ'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getBannerZ({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

export type GetJackBannerSchema = z.infer<typeof getJackBannerSchema>;
type ResultGetJackBannerSchema = Result<GetJackBannerSchema, AppError>;

type GetJackBannerArgs = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const getJackBanner = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: GetJackBannerArgs): Promise<ResultGetJackBannerSchema> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);
    return defaultClient.get(
      `/${version}/advertisements/jack-banners`,
      getJackBannerSchema,
    );
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

type UseGetJackBannerOptions = {
  config?: UseQueryOptions<ResultGetJackBannerSchema>;
  version?: string;
};

export const useGetJackBanner = ({
  version,
  config,
}: UseGetJackBannerOptions) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetJackBannerSchema>({
    ...config,
    queryKey: ['jackBanners'],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getJackBanner({cookies, version});
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};

type GetMypageJackBannerSettingArgs = {
  cookies: Partial<Record<string, string>>;
  queryParams: {
    is_mobile: boolean;
  };
  version?: string;
};

export const getMypageJackBannerSetting = async ({
  cookies,
  queryParams,
  version = DEFAULT_API_VERSION,
}: GetMypageJackBannerSettingArgs): Promise<ResultGetMypageJackBannerSetting> => {
  const request = async (accessToken: string) => {
    defaultClient.setToken(accessToken);

    const path = createPathWithQuery(
      `/${version}/advertisements/mypage-jack-banner-setting`,
      {...queryParams},
    );
    return defaultClient.get(path, AdvertisementMypageJackBannerSettingSchema);
  };

  const result = await sendApiRequest({
    cookies,
    authRequired: true,
    request,
    version,
  });

  return result;
};

export type GetMypageJackBannerSettingResponse = z.infer<
  typeof AdvertisementMypageJackBannerSettingSchema
>;
export type ResultGetMypageJackBannerSetting = Result<
  GetMypageJackBannerSettingResponse,
  AppError
>;

type UseGetMypageJackBannerSetting = {
  version?: string;
  queryParams: {
    is_mobile: boolean;
  };
  config?: UseQueryOptions<ResultGetMypageJackBannerSetting>;
};

export const useGetMypageJackBannerSetting = ({
  version = DEFAULT_API_VERSION,
  queryParams,
  config,
}: UseGetMypageJackBannerSetting) => {
  const {updateIsSessionExpired} = useIsSessionExpired();
  return useQuery<ResultGetMypageJackBannerSetting>({
    ...config,
    queryKey: ['getMypageJackBannerSetting', queryParams.is_mobile],
    async queryFn() {
      const cookies = parseCookies();
      const result = await getMypageJackBannerSetting({
        cookies,
        queryParams,
        version,
      });
      if (!result.ok && result.error === InvalidTokenError) {
        updateIsSessionExpired(true);
      }

      return result;
    },
  });
};
